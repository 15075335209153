@charset "utf-8";


body.page_home,body.page_english{

	.myMainvisual{
		background-color: #CCCCCC;

		.container{
			@include tabletP{
				max-width:none;
				padding-right: 0;
				padding-left: 0;
			}
		}

		.inner{
			background-image: url(../img/top/mainvisual.jpg);
			background-position: center;
			background-repeat: no-repeat;
			position: relative;
			height: 530px;
			@include tabletP{
				background-image: url(../img/top/mainvisual_sp.jpg);
				background-size: cover;
				height: 30vh;
			}


			.label{
				width: 170px;
				@include tabletP {
					width: 132px;
				}

				@include sphoneP {
					width: 85px;
				}

				&Group {
					display: flex;
					position: absolute;
					left:15px;
					bottom:15px;
					> * {
						&:not(:last-child) {
							margin-right: 10px;
						}
					}
				}
			}

		}
	}//.myMainvisual

	.myBlockHead{
		padding-top: 50px;
		padding-bottom: 50px;

		.container{
			display: flex;
			flex-wrap: wrap;
			justify-content:space-between;
			@include sphoneP{
				display: block;
			}
		}

		.box{
			width: 48%;
			@include sphoneP{
				width: auto;

				& + .box{
					margin-top: (100/3)+px;
				}
			}
		}

		.foot{
			margin-top: 15px;
			text-align: right;
			@include sphoneP{
				margin-top: 5px;
			}
		}

		.wrap{
			height: (53*9)+38+10px;
			overflow: auto;
			@include sphoneP{
				height: (560/3)+px;
			}
		}
	}//.myBlockHead

	.myBlockAbout{

		.block{
			display: flex;
			flex-wrap: wrap;
			justify-content:space-between;
			background-color: #F8F9FC;
			@include sphoneP{

			}

			&:nth-child(even){
				background-color: #fff;
			}
		}

		.box{
			width: 50%;
			@include tabletP{
				width: 100%;
			}

			&.detail{
				padding-top: 50px;
				padding-bottom: 50px;
				padding-right: $containerPadding+px;
				padding-left: $containerPadding+px;
				@include tabletP{
					order:1;
					padding: 30px;
				}

				.body{
					max-width:540px;
					margin-right: auto;
					margin-left: auto;
					>p + p,
					>p + ul,
					ul + p{
						margin-top: 20px;
					}
				}

				.catch{
					text-align: center;
					@include fz(24);
					@include sphoneP{
						@include fz(48/3);
					}

					br{
						&.sp{
							display: none;
							@include sphoneP{
								display:inline;
							}
						}
					}
				}

			}

			&.image{
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				@include tabletP{
					height: 40vw;
					order:2;
				}
			}
		}

		.block01{
			.box{
				&.image{
					background-image: url(../img/top/ph_about01.jpg);
				}
			}
		}

		.block02{
			.box{
				&.image{
					background-image: url(../img/top/ph_about02.jpg);
					background-position: center top;
				}
			}
		}

		.block03{
			.box{
				&.image{
					background-image: url(../img/top/ph_about03.jpg);
				}
			}
		}

		.block04{
			.box{
				&.image{
					background-image: url(../img/top/ph_about04.jpg);
				}
			}
		}

	}//.myBlockAbout


	.myListInfo{
		display: flex;
		flex-wrap: wrap;
		justify-content:space-between;
		@include sphoneP{
			display: block;
		}

		.item{
			display: block;
			position: relative;
			width: 30.3%;
			background-color: #F8F9FC;
			margin-top: 4%;
			text-decoration: none;
			@include sphoneP{
				width: auto;
				margin-top: 0;
			}

			&:nth-child(-n + 3){
				margin-top: 0;
			}

			&:not(:last-child){
				@include sphoneP{
					margin-bottom: (70/3)+px;
				}
			}

			&::after{
				content:"";
				transition:all 0.2s ease;
				position: absolute;
				top:0;
				left:0;
				right: 0;
				bottom: 0;
				border:solid 5px #CBE4F5;
				opacity: 0;
				@include sphoneP{
					display: none;
				}
			}
		}

		a.item{
			&:hover{
				box-shadow: rgba(0,0,0,0.15)  0px 0 10px ;
				opacity: 1;
				&::after{
					opacity: 1;
				}
			}
		}


		.image{
			text-align: center;
		}


		.body{
			padding: 20px;
			@include sphoneP{
				text-align: center;
			}
		}

		.title{
			color:nth($mainColor,1);
			@include fz(20);
			@include sphoneP{
				@include fz(40/3);
			}
		}

		.txt{
			@include fz(14);
			@include sphoneP{
				@include fz(28/3);
			}

		}

	}//.myListInfo

	.myListLink{
		display: flex;
		flex-wrap: wrap;
		justify-content:space-between;
		@include sphoneP{
		}

		.item{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			width: 15.5%;
			background-color: #fff;
			padding: 20px 10px;
			text-decoration: none;
			text-align: center;
			@include sphoneP{
				width: 48%;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				padding: 10px 10px 10px 0;
				margin-top: 3%;
				&:nth-child(-n + 2){
					margin-top: 0;
				}
			}
		}

		a.item{
			&:hover{
				opacity: 1;
				box-shadow: rgba(0,0,0,0.1)  0px 0 10px ;
			}
		}

		.item01{
			.image{
				img{
					@include sphoneP{
						width: (50/3)+px;
					}
				}
			}
		}

		.item02{
			.image{
				img{
					@include sphoneP{
						width: (68/3)+px;
					}
				}
			}
		}

		.item03{
			.image{
				img{
					@include sphoneP{
						width: (69/3)+px;
					}
				}
			}
		}

		.item04{
			.image{
				img{
					@include sphoneP{
						width: (69/3)+px;
					}
				}
			}
		}

		.item05{
			.image{
				img{
					@include sphoneP{
						width: (55/3)+px;
					}
				}
			}
		}



		.inner{
			width: 100%;
			@include sphoneP{
				display: flex;
				flex-wrap: wrap;
				justify-content:space-between;
				align-items:center;
			}
		}

		.image{
			margin-bottom: 10px;
			height: 50px;
			line-height: 50px;
			@include sphoneP{
				width: 50px;
				height: auto;
				margin-bottom: 0;
				line-height: 1.5;
			}

			img{
				vertical-align: middle;
			}

		}

		.txt{
			@include fz(14);
			line-height: 1.4;
			@include tabletP{
				@include fz(12);
			}
			@include sphoneP{
				flex:1;
				@include fz(28/3);
				text-align: left;
			}
		}

	}//.myListLink


	.myBlockContact{
		display: flex;
		flex-wrap: wrap;
		justify-content:space-between;
		@include sphoneP{
			display: block;
			padding-right: 30px;
			padding-left: 30px;
			padding-bottom: 30px;
		}


		.box{
			width: 50%;
			text-align: center;
			position: relative;
			@include sphoneP{
				width: auto;
			}

			&:last-child{
				@include sphoneP{
					margin-top: 25px;
					padding-top: 25px;
				}

				&::after{
					content:"";
					position: absolute;
					top:0;
					left:0;
					bottom: 0;
					background-color: #D4D7D9;
					width: 1px;
					@include sphoneP{
						width: auto;
						bottom:auto;
						right: 0;
						height: 1px;
					}
				}
			}

			dt{
				margin-bottom: 15px;

			}


			.tel{
				font-weight: bold;
				@include fz(38);
				letter-spacing: 0.1em;
				line-height: 1.3;
				@include sphoneP{
					letter-spacing:0;
					@include fz(72/3);
				}

				a{
					text-decoration: none;
					color:$baseColor;
				}
			}


			.btn{
				max-width:280px;
				margin-right: auto;
				margin-left: auto;
				@include sphoneP{
					max-width:none;
				}
			}
		}

	}//.myBlockContact

	.mySDGs{
		position: relative;
		* > & {
			margin-top: 16px;
			@include sphoneP {
				margin-top: 7px;
			}
		}

		&-title {
			color: nth($mainColor,1);
			@include fz(20);
			line-height: 1;
			@include sphoneP {
				@include fz(40/3);
			}
		}
		&-txt {
			@include fz(14);
			line-height: 1.4;
			@include sphoneP {
				@include fz(28/3);
			}
		}
		&-image {
			text-align: center;
		}
		&-wrap {
			display: block;
			padding: 20px 34px;
			& > *:not(:first-child) {
				margin-top: 12px; 
			}
			@include sphoneP {
				padding: 16px 24px;
			}
		}
		a {
			text-decoration: none;
			background-color: #E9ECF7;

			&::after{
				content:"";
				transition:all 0.2s ease;
				position: absolute;
				top:0;
				left:0;
				right: 0;
				bottom: 0;
				border:solid 5px #CBE4F5;
				opacity: 0;
				@include sphoneP{
					display: none;
				}
			}
	
			&:hover{
				box-shadow: rgba(0,0,0,0.15)  0px 0 10px ;
				opacity: 1;
				&::after{
					opacity: 1;
				}
			}
		}
	}//.mySDGs
}
