@charset "utf-8";

.modTbl01{
	border-top: solid 1px #ccc;
	@include fz(14);
	@include sphoneP{
		@include fz(11);
	}

	* + &{
		margin-top: 50px;
	}

	th,td{
		border-bottom: solid 1px #ccc;
		padding: 10px 30px;
		@include sphoneP{
			padding: 5px;
		}
	}


	th{
		font-weight: bold;
	}

	tr{
		&:nth-child(odd){
			th,td{
				background-color: #F6F6F6;
			}
		}
	}

}//.modTbl01

.modTblHistory{
	@include fz(14);
	@include sphoneP{
		@include fz(11);
	}

	th,td{
		border-bottom: dotted 1px #ccc;
		padding: 10px 30px;
		vertical-align: top;
		@include sphoneP{
			padding: 5px;
		}
	}

	th{
		width: 15em;
		color:#ccc;
		@include sphoneP{
			width: 13em;
		}
	}


}//.modTblHistory