@charset "utf-8";

/* Reset
------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td
{
	margin: 0;
	padding: 0;
}
address,
em
{
	font-style: normal;
}
strong,
th
{
	font-weight: normal;
}
table
{
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}
th
{
	text-align: left;
}
hr,
legend
{
	display: none;
}
h1,
h2,
h3,
h4,
h5,
h6
{
	font-size: 100%;
	font-weight: normal;
}
img,
fieldset
{
	border: 0;
}
img
{
	border:0;
	vertical-align:top;
	max-width:100%;
	height:auto;
}

li
{
	list-style-type: none;
}
input[type="submit"],button,label,select{
	cursor:pointer;
}

input[type="submit"],button{
	&:hover{
		
	}
}

.clearfix{
	@include clearfix;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* link
------------------------------------------------------------*/
a { 
	color: $baseLinkColor;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}
a,button{
	transition:all 0.2s ease;
	&:hover {
		opacity:0.8;
	}
}

/* Fonts
------------------------------------------------------------*/

html{ font-size: 62.5%; }/* 10px base */

body{
	-webkit-text-size-adjust: 100%;
	word-wrap : break-word;
	overflow-wrap : break-word; 
	font-family:$baseFontFamily;
	line-height:$baseLineHeight;
	color: $baseColor;
	@include fz;
	//IE11以下は別のフォントを指定する場合
	// &.IEUnder{
	// 	font-family: "メイリオ",Verdana, "Hiragino Kaku Gothic ProN",  Meiryo, sans-serif;
	// }
	@include sphoneP{
		@include fz(12);
	}
}

input,button,textarea,select
{
	line-height:$baseLineHeight;
	color: $baseColor;
	font-family:$baseFontFamily;
	//IE11以下は別のフォントを指定する場合
	// .IEUnder &{
	// 	font-family: "メイリオ",Verdana, "Hiragino Kaku Gothic ProN",  Meiryo, sans-serif;
	// }
}

/* form
------------------------------------------------------------*/

$placeholderColor :#a9a9a9;

textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"]
{
	width: 100%;
	background-color: #f2f2f2;
	&::-webkit-input-placeholder{
		color:$placeholderColor;
	}
	&::-moz-placeholder{
		color:$placeholderColor;
	}
	&:-ms-input-placeholder{
		color:$placeholderColor;
	}

	&.small{
		max-width: 85px;
		& + span{
			margin-left: 8px;
		}
	}
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"]{
	width: 400px;
	max-width:100%;
}
textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
select{
	padding:10px;
	border-radius:0;
	border:none;
	@include fz(16);
}


select{
	background-color: #f2f2f2;
	height: 50px;
	padding-top: 0;
	padding-bottom: 0;
	line-height: 50px;
}


textarea.error,
input[type="text"].error,
input[type="password"].error,
input[type="tel"].error,
input[type="email"].error,
select.error{
	background-color:rgba(255,242,242,1.00);
}



/* radio
-------------*/
input[type="radio"] {
	display: none;
}

input[type="radio"] + label {
	position: relative;
	display: inline-block;
	padding: 3px 10px 3px 30px;
	cursor: pointer;
	width:100%;
}

input[type="radio"] + label::before,
input[type="radio"] + label::after {
	content: '';
	top: 50%;
	position: absolute;
	border-radius: 100%;
	transition: all .2s;
}

input[type="radio"] + label::before {
	width: 20px;
	height: 20px;
	margin-top: -9px;
	left: 0;
	background: map-get($checkboxColor, 'bg');
	border: 1px solid map-get($checkboxColor, 'border');
}
input[type="radio"].error + label::before{
	border: 1px solid map-get($checkboxColor, 'error');
	background-color:map-get($checkboxColor, 'bg-error');
}
input[type="radio"] + label:hover::before {
	// background: map-get($checkboxColor, 'bg');
}
input[type="radio"] + label::after {
	opacity: 0;
	left: 5px;
	width: 10px;
	height: 10px;
	margin-top: -4px;
	background: map-get($checkboxColor, 'check');;
	transform: scale(0.5);
}
input[type="radio"]:checked + label::before {
	background: map-get($checkboxColor, 'bg');
	border: 1px solid map-get($checkboxColor, 'border-check');
}
input[type="radio"]:checked + label::after {
	opacity: 1;
	transform: scale(1);
}


/* checkbox
-------------*/
input[type="checkbox"] {
	display: none;
}
input[type="checkbox"] + label {
	position: relative;
	display: inline-block;
	padding: 3px 10px 3px 30px;
	cursor: pointer;
	-webkit-transition: all .2s;
	transition: all .2s;
}
input[type="checkbox"] + label::before,
input[type="checkbox"] + label::after {
	position: absolute;
	content: '';
}
input[type="checkbox"] + label::before {
	top: 50%;
	left: 0;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	background-color: map-get($checkboxColor, 'bg');
	border: 1px solid map-get($checkboxColor, 'border');
}
input[type="checkbox"] + label::after {
	opacity: 0;
	top: 50%;
	left: 4px;
	width: 12px;
	height: 8px;
	margin-top: -6px;
	border-left: 3px solid map-get($checkboxColor, 'check');
	border-bottom: 3px solid map-get($checkboxColor, 'check');
	transform: rotate(-45deg) scale(0.5);
}
input[type="checkbox"] + label:hover::before {
	background: #fff;
}
input[type="checkbox"]:checked + label::before {
	background: #fff;
	border: 1px solid map-get($checkboxColor, 'border-check');
}
input[type="checkbox"]:checked + label::after {
	opacity: 1;
	transform: rotate(-45deg) scale(1);
}


