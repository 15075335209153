@charset "utf-8";

@mixin tabletP {
  //タブレット縦の場合
  @media only screen and (max-width: $bpTabletL) {
  	@content;
  }
}

@mixin sphoneP {
  //スマートフォン縦の場合
  @media only screen and (max-width: $bpSphoneP) {
  	@content;
  }
}

@mixin sphoneSE {
  @media only screen and (max-width: 320px) {
  	@content;
  }
}

// webfont icon
@mixin iconFont() {
	font-family: FontAwesome;
	line-height:1;
}

// clearfix
@mixin clearfix() {
	&:after {
		content:".";
		display:block;
		clear:both;
		height:0;
		visibility:hidden;
	}
}

// font-size
@mixin fz($size: $baseFont,$important:false) {
	font-size: if($important, $size + px!important, $size + px);
	font-size: if($important, ($size / 10) + rem!important, ($size / 10) + rem);
}


@mixin keyframes($animation-name: animaton) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation($animation-name,$s,$easing,$count,$end:none,$delay:0) {
	-webkit-animation: $animation-name;
	-moz-animation: $animation-name;
	animation: $animation-name;

	-webkit-animation-duration: $s;
	animation-duration:$s;

	-webkit-animation-timing-function: $easing;
	animation-timing-function: $easing;

	-webkit-animation-iteration-count: $count;
	animation-iteration-count:$count;

	-webkit-animation-fill-mode: $end;
	animation-fill-mode: $end;

	-moz-animation-delay: $delay;
	animation-delay: $delay;
}
