@charset "utf-8";


.modBtn01{
	display: block;
	background-color: transparent;
	width: 100%;
	appearance: none;
	border:solid 1px nth($mainColor,1);
	padding: 15px 10px;
	border-radius:4px;
	color:nth($mainColor,1);
	text-decoration: none;
	text-align: center;
	line-height: 1.5;

	&:hover{
	}

	&_color01{

		&:hover{
			background-color: nth($mainColor,1);
			opacity: 1;
			color:#fff;
		}

	}

	&_color02{
		background-color: nth($mainColor,1);
		color:#fff;
	}


	i{
		margin-left: 4px;
	}

}//.modBtn01

.modBtnBox{
	max-width:340px;
	margin-right: auto;
	margin-left: auto;

	* + &{
		margin-top: 40px;
	}

}//.modBtnBox


.modBtnList{
	display: flex;
	flex-wrap: wrap;
	justify-content:space-between;
	max-width:760px;
	margin: 0 auto;

	* + &{
		margin-top: 70px;
		@include sphoneP{
			margin-top: 40px;
		}
	}

	.item{
		width: 48%;
		@include sphoneP{
			width: 100%;

			& + .item{
				margin-top: 15px;
			}
		}

		&.order1{
			@include sphoneP{
				order:1;
				margin-top: 0;
			}
		}

		&.order2{
			@include sphoneP{
				order:2;
				margin-top: 15px;
			}
		}
	}

}//.modBtnList