@charset "utf-8";


.modTxtLink{
	display: inline-block;
	text-decoration: none;
	color:nth($mainColor,1);
	@include fz(15);
	@include sphoneP{
		@include fz(32/3);
	}

	i{
		margin-right: 4px;
	}
}//.modTxtLink

.modTxtBlock{
	max-width: 570px;
	margin-right: auto;
	margin-left: auto;

	& + .modTxtBlock{
		margin-top: 60px;
	}

	&_wide{
		max-width:none;
	}

	p + p{
		margin-top: 15px;
	}

}//.modTxtBlock

.modTxtCatch{
	margin-bottom: 40px;
	text-align: center;
	line-height: 1.2;
	@include fz(38);
	@include sphoneP{
		margin-bottom: (70/3)+px;
		@include fz(20);
	}
}//.modTxtCatch

.modTxtRight{
	text-align: right;
}//.modTxtRight

.modTxtCenter{
	text-align: center;
}//.modTxtCenter