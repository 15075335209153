@charset "utf-8";
/*
#overview
modList
*/

/*
#styleguide
modList　modIndent

```
<ul class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ul>
<ol class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ol>
<ul class="modIndent01">
    <li>※テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>※リスト</li>
</ul>
<ul class="modIndent02">
    <li>１.　テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>２.　リスト</li>
</ul>
<ul class="modList01">
    <li>Illustrator に原寸で配置したサイズでの解像度が350dpiになるようにしてください。</li>
    <li> 拡大縮小率70%〜141％を超えると画質が劣化するのでご注意ください。</li>
</ul>
```
*/

.modListNormal { margin: 0 0 0 2em; }

ul.modListNormal {
	li{
		&:before {
			content: "\0030fb";
			margin-left:-2em;
			display: inline-block;
			width:2em;
			text-align:center;
		}
	}
}

ol.modListNormal li { list-style:decimal; }

/* indent */
.modIndent01 li {
	text-indent: -1em;
	margin: 0 0 0 1em;
}

.modIndent02 li {
	text-indent: -2.5em;
	margin: 0 0 0 2.5em;
}

.modListNormal{

}//.modListNormal

.modListPost{

	li{
		border-bottom: solid 1px #E5E5E5;
		padding-bottom: 15px;
		@include sphoneP{
			padding-bottom: (40/3)+px;
		}

		& + li{
			margin-top: 15px;
			@include sphoneP{
				margin-top: (40/3)+px;
			}
		}

		.item{
			display: flex;
			flex-wrap: wrap;
			justify-content:space-between;
			padding:0 10px ;
		}

		.date{
			width: 6em;
			color:#B1B1B1;
			@include fz(14);
			@include sphoneP{
				width: 5em;
				@include fz(10);
			}
		}

		.category{
			width: 42px;
			padding-right: 15px;
			padding-top: 0.2em;
			box-sizing: content-box;
			@include sphoneP{
				width: 32px;
				padding-right: 5px;
			}
		}

		.txt{
			flex:1;
			@include fz(15);
			line-height: 1.5;
			@include sphoneP{
				@include fz(11);
			}
		}

		a{
			text-decoration: none;
			&:hover{
				opacity: 1;
				color:nth($mainColor,1);
			}

			i{
				margin-left: 4px;
			}
		}
	}

}//.modListPost

.modListImage{
	display: flex;
	flex-wrap: wrap;
	justify-content:space-between;


	& + .section{
		margin-top: 30px;
	}

	li{
		width: 31.8%;
		margin-top: 2%;
		@include sphoneP{
			width: 48%;
			margin-top: 0;
			margin-bottom: 3%;
		}
		&:nth-child(-n + 3){
			margin-top: 0;
		}
	}

	&_type02{
		li{
			width: 49%;
			margin-top: 1.5%;
			@include sphoneP{
				margin-top: 0;
				margin-bottom: 1.5%;
			}
			&:nth-child(-n + 2){
				margin-top: 0;
			}

			&:nth-child(3){
				margin-top: 1.5%;
				@include sphoneP{
					margin-top: 0;
				}
			}
		}
	}

}//.modListImage

.modListTxt{
	@include clearfix;

	* + &{
		margin-top: 20px;
	}

	li{
		float: left;
		margin-bottom: 5px;
		&:not(:last-child){
			margin-right: 20px;
		}
	}
}