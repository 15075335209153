@charset "utf-8";


@include keyframes(rotateLoading){

	0% {
	  -webkit-transform: rotate(0deg) scale(1);
	          transform: rotate(0deg) scale(1); }
	50% {
	  -webkit-transform: rotate(180deg) scale(0.6);
	          transform: rotate(180deg) scale(0.6); }
	100% {
	  -webkit-transform: rotate(360deg) scale(1);
	          transform: rotate(360deg) scale(1); }

}


/*↓消さないこと*/
.modLoading{
	position:fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	background-color:rgba(255,255,255,0.8);

	.inner{
		position: absolute;
		top:0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 42px;
		height: 42px;
	}

	.inner > div {
		background-color: #666;
		height: 40px;
		width: 40px;
		border-radius: 100%;
		margin: 2px;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		border: 2px solid #666;
		border-bottom-color: transparent;
		background: transparent !important;
		display: inline-block;
		@include animation(rotateLoading,0.75s,linear,infinite);
	}

}
/*↑消さないこと*/


.modCategory{
	display: block;
	border-radius:3px;
	padding: 4px 3px;
	@include fz(11);
	text-align: center;
	line-height: 1.2;
	@include sphoneP{
		padding: 2px;
		@include fz(8);
	}

	@each $name, $color in $report_color {
		&_#{$name}{
			background-color:  map-get( map-get($categoryColor, $color), color1 );
			color:  map-get( map-get($categoryColor, $color), color2 );
		}
	}

	&_important{
		background-color:  map-get( map-get($categoryColor, 'important'), color1 );
		color:  map-get( map-get($categoryColor, 'important'), color2 );
	}


}//.modCategory

.modNav01{
	background-color: #F8F9FC;
	padding: 20px 0 15px;
	@include fz(15);
	@include sphoneP{
		@include fz(12);
	}

	.container{
		display: flex;
		flex-wrap: wrap;
		justify-content:center;
	}

	.item{
		margin:0 15px;

	}

	a{
		display: inline-block;
		margin:0 0 5px;
		text-decoration: none;
		color:nth($mainColor,1);
		&:hover{
			text-decoration: underline;
		}
	}

}//.modNav01


.modNav02{

	display: flex;
	flex-wrap: wrap;
	justify-content:space-between;
	@include sphoneP{
		display: block;
	}

	.item{
		display: block;
		position: relative;
		width: 30.3%;
		background-color: #F8F9FC;
		margin-top: 4%;
		text-decoration: none;
		@include sphoneP{
			width: auto;
			margin-top: 0;
		}

		&:nth-child(-n + 3){
			margin-top: 0;
		}

		&:not(:last-child){
			@include sphoneP{
				margin-bottom: (70/3)+px;
			}
		}

		&::after{
			content:"";
			transition:all 0.2s ease;
			position: absolute;
			top:0;
			left:0;
			right: 0;
			bottom: 0;
			border:solid 5px #CBE4F5;
			opacity: 0;
			@include sphoneP{
				display: none;
			}
		}
	}

	a.item{
		&:hover{
			box-shadow: rgba(0,0,0,0.15)  0px 0 10px ;
			opacity: 1;
			&::after{
				opacity: 1;
			}
		}
	}


	.image{
		text-align: center;

		img{
			width: 100%;
		}
	}


	.body{
		padding: 15px 20px;
		@include sphoneP{
			text-align: center;
		}
	}

	.title{
		color:nth($mainColor,1);
		@include fz(20);
		@include sphoneP{
			@include fz(40/3);
		}
	}

	.txt{
		@include fz(14);
		@include sphoneP{
			@include fz(28/3);
		}

	}
}


.modPager{
	text-align: center;
	@include sphoneP{
		position: relative;
	}

	* + &{
		margin-top: 30px;
	}

	.wp-pagenavi{
		display:inline-flex;
		flex-wrap: wrap;
		justify-content:center;
		align-items:center;
		padding: 0 106px;
		position: relative;
		@include sphoneP{
			position: static;
			padding: 0 6em;
		}
		@include sphoneSE{
			padding: 0 5em;
		}
	}


	.page,.current{
		display: block;
		height: 40px;
		line-height: 40px;
		padding: 0 12px;
		margin: 0 5px;
		@include fz(15);
		text-decoration: none;
		@include sphoneP{
			margin: 0 3px;
			@include fz(14);
		}

		@include sphoneSE{
			@include fz(12);
		}
	}


	.page{
		background-color: #f1f1f1;

		&:hover{
			opacity: 1;
			background-color: nth($mainColor,1);
			color:#fff;
		}
	}


	.current{
		background-color: nth($mainColor,1);
		color:#fff;
	}


	.previouspostslink,.nextpostslink{
		display: block;
		position: relative;
		margin: auto;
		width: 40px;
		height: 40px;
		line-height: 40px;

		&::before{
			content: '';
			left: 0;
			right: 0;
			display: block;
			width: 8px;
			height: 8px;
			margin: auto;
			position: absolute;
			top: calc(50% + 2px);
			text-indent: 0;
		}
	}

	.previouspostslink{
		&:before{
			border-left: solid 2px;
			border-bottom: solid 2px;
			transform-origin: center center;
			transform: rotate(45deg) translate(-50%,-50%);
			@include sphoneP{
			}
		}

	}

	.nextpostslink{
		&:before{
			border-right: solid 2px;
			border-top: solid 2px;
			transform-origin: center center;
			transform: rotate(45deg) translate(-50%,-50%);
			@include sphoneP{
			}
		}

	}


}//.modPager