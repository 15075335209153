@charset "utf-8";


.modTitlePage{
	background-image: url(../img/common/bg_title.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-color: #CCCCCC;
	padding: 50px 0;
	text-align: center;
	color:#fff;
	line-height: 1.3;
	@include tabletP{
		background-position: right -100vw center;
		background-size: auto 100%;
	}


	.en{
		@include fz(46);
		font-family: $fontRaleway;
		font-weight: 300;
		@include sphoneP{
			@include fz(90/3);
		}
	}

	.title{
		@include fz(14);
		@include sphoneP{
			@include fz(28/3);
		}
	}
}

.modTitle01{
	margin-bottom: 40px;
	text-align: center;
	line-height: 1.2;
	@include fz(38);
	@include sphoneP{
		margin-bottom: (70/3)+px;
		@include fz(20);
	}

	&_left{
		text-align: left;
	}

	.sub,.main{
		display: block;
	}


	.sub{
		@include fz(46);
		font-family: $fontRaleway;
		font-weight: 300;
		@include sphoneP{
			@include fz(90/3);
		}

	}

	.main{
		@include fz(14);
		@include sphoneP{
			@include fz(28/3);
		}
	}

}//.modTitle01

.modTitle02,
.modBlockWysiwyg h2
{
	padding-left: 20px;
	margin-bottom: 20px;
	position: relative;
	@include fz(24);
	line-height: 1.5;
	@include sphoneP{
		@include fz(51/3);
	}

	&::before{
		content:"";
		position: absolute;
		top:0.2em;
		left:0;
		bottom:0.2em;
		background-color: nth($mainColor,1);
		width: 4px;
	}

	&.modTitle02_type02{
		padding-left: 0;
		&::before{
			display: none;
		}
	}


	i{
		margin-right: 8px;
		color:#999999;
		font-size: 20px;
		@include sphoneP{
			font-size:(43/3);
		}
	}

}//.modTitle02

.modBlockWysiwyg h3,
.modTitle03{
	margin-bottom: 15px;
	@include fz(20);
	@include sphoneP{
		@include fz(16);
	}
}//.modTitle03


.modBlockWysiwyg h4,
.modTitle04{
	margin-bottom: 10px;
	font-weight: bold;

	p + &{
		margin-top: 20px;
	}
}//.modTitle04




