@charset "utf-8";

body.page_schoollife{

	.myBlockSchedule{
		display: flex;
		flex-wrap: wrap;
		justify-content:space-between;
		border-top: solid 1px #ccc;
		@include sphoneP{
			display: block;
		}

		.item{
			width: 50%;
			display: flex;
			flex-wrap: wrap;
			justify-content:space-between;
			border-bottom: solid 1px #ccc;
			@include sphoneP{
				width: auto;
			}

			.title,.detail{
				padding: 20px 30px;
				@include sphoneP{
					padding: 10px 15px;
				}
			}

			.title{
				width: 36%;
				background-color: #F6F6F6;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				text-align: center;
				@include fz(14);

				span,
				.num{
					display: inline;
					margin-right: 3px;
					@include fz(32);
					font-weight: 500;
					@include sphoneP{
						@include fz(28);
					}
				}

				.inner{
					p {
						display: inline;
					}
				}
			}

			.detail{
				width:64%;

				.inner{
				}
			}

		}

	}//.myBlockSchedule


	* + .myBlockSchedule{
		margin-top: 60px;
	}

}
