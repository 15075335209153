@charset "utf-8";
/*
#overview
modForm
*/

/*
#styleguide
***

```
```
*/

.modFormLead{
	margin: 0 0 50px;
	text-align: center;
	line-height: 2;
	@include sphoneP{
		margin-bottom: 30px;
	}

	.required{
		display: inline-block;
		background:nth($requiredColor,1);
		border-radius:3px;
		color:#FFF;
		@include fz(13);
		padding:2px 10px;
		margin: 0 5px 0 0;
		font-weight: 500;
		line-height: 1.4;

		a{
			color:nth($mainColor,1);
		}
	}

}//.modFormLead

.modFormTxtError {
	display: block;
	margin: 6px 0 0;
	color: #ff0000;
	@include fz(14);
}//.modFormErrorTxt

.modFormBlock{
	border-top:solid 1px #ccc;

	.item{
		display: table;
		width: 100%;
		border-bottom:solid 1px #ccc;
		@include sphoneP{
			display: block;
			width: auto;
			padding: 20px 0 ;
		}
	}

	dt{
		display: table-cell;
		vertical-align: top;
		width:19em;
		padding:24px 0 15px 90px;
		position:relative;
		font-weight: 500;
		@include sphoneP{
			display: block;
			width: auto;
			padding: 0;
			margin-bottom: 10px;
		}


		&.required{
			@include sphoneP{
				padding-left: 60px;
			}
		}

		&.required:before,
		&.any:before{
			position:absolute;
			left:30px;
			top:27px;
			color:#FFF;
			@include fz(13);
			padding:3px 10px;
			line-height: 1.3;
			@include sphoneP{
				top:0.2em;
				left:0;
			}
		}
		&.required:before{
			content:"必須";
			background: nth($requiredColor,1);
			border-radius:3px;
		}
		&.any:before{
			// background:#999999;
			// content:"任意";
		}
	}

	&_conf{
		dt{
			padding-top: 15px;

			&.required:before,
			&.any:before{
				top:20px;
			}
		}
	}

	dd{
		display: table-cell;
		vertical-align: top;
		padding: 15px 0;
		@include sphoneP{
			display: block;
			padding: 0;
		}
	}

}//.modFormBlock

.modFormAge{
	display: flex;
	flex-wrap: wrap;
	align-items:center;


	input{
		width: 80px;
		margin-right: 10px;
	}

}//.modFormAge



.modFormAddress{

	.zip{
		display: table;
		table-layout: fixed;
		width: 100%;
		@include sphoneP{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}

		li{
			display: table-cell;
			vertical-align: middle;
			@include sphoneP{
				display: block;
			}
		}


		.input{
			width: 200px;
			@include sphoneP{
				width: 40%;
			}
		}

		.btn{
			padding-left: 15px;
			@include sphoneP{
				// padding-left: 0;
			}

			a{
				// border:solid 1px nth($subColor,3);
				background-color: nth($mainColor,1);
				padding:10px 18px;
				display: inline-block;
				text-decoration: none;
				color:#fff;
				font-weight: bold;
				// font-family: $fontYugo;
				@include sphoneP{
					padding-right: 10px;
					padding-left: 10px;
				}
			}
		}

	}

	.box{
		margin-top: 20px;

		select{
			width: 200px;
			max-width:100%;
		}
	}
}//.modFormAddress


.modFormList{
	@include clearfix;

	li{
		float: left;

		&:not(:last-child){
			margin-right: 40px;
		}
	}

}//.modFormCheckList

.modFormComp{
	text-align: center;

	.title{
		margin-bottom: 30px;
		font-weight: bold;
		@include fz(24);
		@include sphoneP{
			@include fz(20);
		}
	}

	p + p{
		margin-top: 20px;
	}
}
.modFormContact{
	background-color: #fff;
	text-align: center;
	padding: 16px 30px 20px;
	font-weight: bold;

	* + &{
		margin-top: 30px;
	}

	dt,dd{
	}

	dt{
		display: block;
		margin-bottom: 15px;
		padding-bottom: 10px;
		border-bottom: solid 1px #cccccc;
		@include fz(17);
		@include sphoneP{
			@include fz(15);
		}
	}


	a{
		color:nth($mainColor,1);
		text-decoration: none;
		&:hover{
			text-decoration: underline;
		}
	}

	dd{
		@include fz(18);
		@include sphoneP{
			@include fz(16);
		}
	}

}//.modFormContact