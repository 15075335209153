
@charset "utf-8";
/*
#overview
base
*/

#header {
	position: relative;
	box-shadow: rgba(0,0,0,0.3)  0px -2px 10px ;
	z-index: 100;
	@include tabletP{
		height: 86px;
	}

	.container{
		@include tabletP{
			padding: 0;
		}
	}

	.head{
		padding: 20px 0;
		display: flex;
		flex-wrap: wrap;
		justify-content:space-between;
		position: relative;
		z-index: 10;
		@include tabletP{
			padding: 0;
		}
	}


	.siteTitle{
		@include tabletP{
			height: 86px;
			padding-left: $containerPadding+px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
		}

		a{
			display: block;
			text-decoration: none;

			img{
				width: auto;
				height: 54px;
			}

			@include tabletP{
				img{
					width: auto;
					height: 60px;
				}
			}

			@include sphoneP{
				img{
					height: 40px;
				}
			}

		}


	}//.siteTitle

	.hNav{
		@include fz(13);
		@include sphoneP{
			@include fz(34/3);
		}

		a{
			color:#707070;
			text-decoration: none;
			&:hover{
				text-decoration: underline;
			}


			i{
				margin-right: 4px;
			}
		}

	}

	.head{

		.hNav{
			@include tabletP{
				display: none;
			}
			ul{
				text-align: right;

				& + ul{
					margin-top: 10px;
				}

				li{
					display:inline;
					&:not(:first-child){
						margin-left: 10px;
					}
				}

			}

		}

	}


	.btnMenu{
		position: absolute;
		top:0;
		right: 0;
		display: block;
		background-color: transparent;
		background-image: url(../img/common/btn_menu.png);
		background-position: 0 0;
		background-repeat: no-repeat;
		background-size: 74px auto;
		width: 74px;
		height: 86px;
		z-index: 100;
		border:none;
		transition:none;
		display: none;
		@include tabletP{
			display: block;
		}
		&.active{
			background-position: 0 -86px;
		}
	}


}//.header

#gNav{
	background-color: #F6F6F6;
	@include tabletP{
		background-color: #fff;
		position: fixed;
		top:86px;
		left:0;
		right: 0;
		bottom:0;
		overflow: auto;
		display: none;
	}


	.inner{
		position: relative;
		@include tabletP{
			padding-top: 10px;
			padding-right: 30px;
			padding-left: 30px;
			padding-bottom: 30px;
		}
	}


	.nav{

		.container{
			display: table;
			width: 100%;
			@include tabletP{
				display: block;
				padding: 0;
			}
		}


		a{
			display: table-cell;
			vertical-align: middle;
			padding: 20px 5px;
			text-decoration: none;
			text-align: center;
			@include tabletP{
				display: block;
				padding: 15px 25px 15px 5px;
				border-bottom: solid 1px #ccc;
				position: relative;
				text-align: left;
			}
			&:hover{
				background-color: nth($mainColor,1);
				color:#fff;
				opacity: 1;
				@include tabletP{
					background-color: transparent;
					color:$baseColor;
				}
			}

			&.active{
				background-color: nth($mainColor,1);
				color:#fff;
				@include tabletP{
					background-color: transparent;
					color:nth($mainColor,1);
				}
			}

			@include tabletP{
				&::after{
					content:"\f105";
					font-family: "Font Awesome 5 Free";
					position: absolute;
					top:0;
					right: 0;
					bottom:0;
					margin:auto;
					height: 1em;
					line-height: 1;
					font-weight: 900;
					font-size: (40/3)+px;
				}
			}
		}

	}

	.hNav{
		display: none;
		@include tabletP{
			display: block;
			margin-top: (110/3)+px;

			li + li{
				margin-top: 10px;
			}
		}
	}

}//.gNav

#contents { }


/*
#styleguide
container

```
<div class="container">
左右にpadding15pxつきます
</div>
```
*/

.container {
	margin-right: auto;
	margin-left: auto;
	padding-right: ($containerPadding)+px;
	padding-left:  ($containerPadding)+px;

}


.section + .section{
	margin-top: 50px;
	@include sphoneP{
		margin-top: 30px;
	}
}



.topicpath{
	padding: 10px 0;
	@include fz(14);

	@include sphoneP{
	}

	ul{
		@include clearfix;
	}

	li{
		float:left;
		@include tabletP{
		}
		&:before{
			margin: 0 8px;
			content:"\f105";
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			color:#ccc;
		}
	}

	a{
		text-decoration: none;

		&:hover{
			text-decoration: underline;
		}
	}

	.home{
		&:before{
			display: none;
		}
	}
}//.topicpath


#footer {


	.fNav{
		border-bottom: solid 1px #E5E5E5;
		padding: 32px 0;
		@include fz(14);
		@include tabletP{
			display: none;
		}

		ul{
			display: flex;
			flex-wrap: wrap;
			justify-content:center;
			text-align: center;


			a{
				display: inline-block;
				margin:0 20px 10px;
				text-decoration: none;
			}


			.active{
				color:nth($mainColor,1);
			}
		}
	}


	.body{
		padding: 36px 0;
		text-align: center;
		@include fz(14);
		@include sphoneP{
			padding: 20px 0;
		}

		.siteTitle{
			margin-bottom: 10px;
			@include fz(24);
			@include sphoneP{
				margin-bottom: 5px;
				@include fz(48/3);
			}
			a{
				text-decoration: none;
			}

			.logo{
				margin-bottom: 10px;
				@include sphoneP{
					margin-bottom: 5px;
				}
			}
		}

		.address{
			color:#666666;
			@include sphoneP{
				@include fz(28/3);
			}

		}

		.info{
			display: flex;
			flex-wrap: wrap;
			justify-content:space-between;
			max-width:20em;
			margin-right: auto;
			margin-left: auto;
			color:#666666;
			@include sphoneP{
				@include fz(28/3);
			}

			li{
				width: 50%;
			}

		}


	}

	.copyright{
		background-color: #F6F6F6;
		padding: 15px 0;
		text-align: center;
		@include fz(13);
		color:#B1B1B1;
		@include sphoneP{
			@include fz(26/3);
		}
	}

	.pageTop{
		position: fixed;
		bottom:30px;
		right: 30px;
		width: 60px;
		height: 60px;
		display: block;
		background-color: #fff;
		border-radius:50%;
		box-shadow: rgba(0,0,0,0.2)  0px 0 10px ;
		text-decoration: none;
		text-align: center;
		line-height: 60px;
		font-size: 24px;
		@include sphoneP{
			width: 40px;
			height: 40px;
			font-size: 16px;
			line-height: 40px;
			right: 20px;
			box-shadow: rgba(0,0,0,0.1)  0px 0 6px ;
		}
	}


}