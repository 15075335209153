@charset "utf-8";


.modBlockInner{
	padding-top: 90px;
	padding-bottom: 90px;
	@include sphoneP{
		padding-top: 30px;
		padding-bottom: 30px;
	}

	&_color02{
		background-color: #F8F9FC;
	}


	&_color03{
		background-color: #ECEFF2;
	}


	&_small{
		padding-top: 20px;
		@include sphoneP{
			padding-top: 30px;
		}
	}

	&_small02{
		padding-top: 40px;
		@include sphoneP{
			padding-top: 20px;
		}
	}

}//.modBlockInner

.modBlockImage{
	text-align: center;

	* + &{
		margin-top: 50px;
	}

}//.modBlockImage


.modBlockDetail{
	display: flex;
	flex-wrap: wrap;
	justify-content:space-between;

	.image{
		width: 47%;
		@include sphoneP{
			width: 100%;
			order:2;
			margin-top: 20px;
		}
	}

	.detail{
		width: 47%;
		@include sphoneP{
			width: 100%;
			order:1;
		}
	}

	& + *{
		margin-top: 50px;
	}
}//.modBlockDetail


.modBlockHead{
	display: flex;
	flex-wrap: wrap;
	align-items:center;
	margin-bottom: 30px;

	.category{
		width: 80px;
	}

	.date{
		color:#ccc;
	}

	.category + .date{
		margin-left: 10px;
	}
}//.modBlockHead


.modBlockWysiwyg{

	iframe{
		width:100%;
		height:400px;
		@include tabletP{
			height:250px;
		}
	}

	ul {
		margin-left: 2em;
		li {
			list-style: disc;
		}
	}

	ol {
		margin-left: 2.2em;
		li {
			list-style: decimal;
		}
	}

	strong,
	th {
		font-weight:bold;
	}

	em{
		font-style:italic;
	}

	hr{
		display: block;
	}

	.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	img.alignright {
		padding: 4px;
		margin: 0 0 2px 7px;
		display: inline;
	}

	img.alignleft {
		padding: 4px;
		margin: 0 7px 2px 0;
		display: inline;
	}

	.alignright {
		float: right;
	}

	.alignleft {
		float: left;
	}

	blockquote{
		background-color: #f6f6f6;
		border-left: solid 4px nth($mainColor,1);
		padding: 50px 20px 20px 45px;
		margin:15px 0;
		position: relative;
		@include sphoneP{
			padding: 35px 20px 15px 35px;
		}

		&::before
		//,
		//&::after
		{
			position: absolute;
			font-family: "Font Awesome 5 Free";
			font-weight: bold;
			font-size: 30px;
			@include sphoneP{
				font-size: 20px;
			}
		}


		&::before{
			content: "\f10d";
			top:5px;
			left:15px;
			@include sphoneP{
				left:10px;
			}
		}

		// &::after{
		// 	content: "\f10e";
		// 	bottom:5px;
		// 	right:15px;
		// }

	}

}//.modBlockWysiwyg


.modBlockMap{

	* + &{
		margin-top: 20px;
	}

	.inner{
		position: relative;
		width: 100%;
		padding-top: 40%;
		iframe {
		  position: absolute;
		  top: 0;
		  right: 0;
		  width: 100% !important;
		  height: 100% !important;
		}
	}

}//.modBlockMap